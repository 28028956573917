
import {Options, Vue} from 'vue-class-component';
import {AccountRecord} from "@/types/api/AccountData";
import { getCreditsHistory } from '@/api';

@Options<BillingInfo>({
  components: {},
  props: {}
})
export default class BillingInfo extends Vue {
  creditsHistory:AccountRecord[]=[];
  pageIndex:number=1;
  pageSize:number=10;
  total:number=0;

  mounted(){
    this.getCreditsHistory()
  }

  getCreditsHistory(){
    getCreditsHistory(this.pageIndex-1,this.pageSize).then(res=>{
      this.total=res.data.data.totalElements;
      this.creditsHistory=res.data.data.content;
    })
  }
}
